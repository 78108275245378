*{
    margin:0;
    padding:0;
    text-decoration: none;
}

a{
    text-decoration: none;
    border: 0;
}

.cEMZEX{
    z-index: -1;
}

.toast-body{
    color: white;
}

@import "./common.css";
