@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.preloader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(34, 34, 34, 0.47);
  z-index: 1000;
}

.preloader_inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(34 34 34 / 15%);
  z-index: 1000;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spin {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.file-list {
  max-width: 1000px;
  border-collapse: collapse;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.file-item:last-child {
  border-bottom: none;
}

.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.file-name {
  flex: 2;
  font-weight: bold;
}

.file-date,
.file-size,
.file-kind {
  flex: 1;
  text-align: right;
  margin-left: 20px;
}

.file-kind {
  text-align: left;
}