$white : #ffffff;
$off-white : #F8F8F8;
$black : #1E1E1E;
$primary  : #1A46B8;
$blue: #1392FF;
$light-blue: #EBF5FE;
$light-blue2: #F8FCFF;
$bright-blue: #30D6FF;
$border-color:#F2F2F2;
$green: #27AE60;
$light-green: #95BDC7;
$Gray_1: #F8F8F8;
$Gray-2: #4F4F4F;
$Gray-3: #828282;
$Gray-5:#E0E0E0;
$Gray-6:#BDBDBD;
$mg-top: 158px;

.user_profile{
    // margin-top: 158px;
    background-color: #F8FCFF;
}
.up_section{
    padding-top: 34px;
    padding-bottom: 60px;
    
    .up_content{
        display: none;
    padding: 40px 316px 124px;
    background-color: #fff;
    border: 1px solid #95CDFF;
    position: relative;
    z-index: 1;
    &.active{
        display: block;
     }
     
    .up_title{
        padding-bottom: 26px;
        h3{
            font-size: 16px;
            line-height: 11.5px;
            font-weight: 500;
            color: $blue;
        }
        }
    .up_image_wrapper{
        width: 200px;
        height: 200px;
        position: absolute;
        left: 3%;
        top: 3%;
        border: 7px solid #E1E1E1;
        border-radius: 50%;
        img{
            border-radius: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .upload_img_ititle{
            position: absolute;
            top: 80%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            text-align: center;

            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #fff;
            &::after{
                content: '';
                background: url(../../images/camera-icon.svg);
                position: absolute;
                top: -100%;
                left: 50%;
                width: 34.73px;
                height: 27.79px;
                transform: translateX(-50%);
            }
        }
    }
    }
    .buttons{
        display: flex;
        gap: 10px;
        .back{
            background-color: #9eabb6;
            border: 1px solid #9eabb6;
        }
    }
    .user-btn{
        border: 1px solid #95CDFF;
        border-radius: 0;
        width: 100%;
        padding: 19px 33px;
        background-color: #95CDFF;
        color: #fff;
    }
}
.input_group .input_field {
    border: 1px solid #95CDFF;
    border-radius: 0;
    width: 100%;
    padding: 19px 33px;
}

.input_group .input_field_label {
    font-size: 14px;
    line-height: 23.1px;
    color: #1392FF;
    padding-bottom: 8px;
}
.input_group_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -24px;
    padding-bottom: 18px;
}
.input_group_content .input_group {
    padding: 0 24px;
    flex: 0 0 50%;
    width: 50%;
}
.up_section .up_content .up_image_wrapper {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 3%;
    top: 3%;
    border: 7px solid #E1E1E1;
    border-radius: 50%;
}
.input_group .input_field_value {
    padding-bottom: 8px;
    font-size: 18px;
    line-height: 23.1px;
}

@media (max-width: 1200px){
    .up_section .up_content {
        padding: 40px 33px 124px 255px;
    }
    .input_group_content {
        margin: 0 -12px;
    }
    .input_group_content .input_group {
        padding: 0 12px;
    }
  }
  @media (max-width: 991.98px){
    .input_group .input_field_value {
        font-size: 15px;
        line-height: 20.1px;
    }
    .up_section .up_content {
        padding: 40px 33px 66px 261px;
    }
  }
  @media (max-width: 767.98px){
    .input_group_content {

        margin: 0 -6px;
        flex-wrap: wrap;
        padding-bottom: 0;
    }
    .input_group_content .input_group {
        padding: 0 6px;
        flex: 0 0 100%;
        width: 100%;
     
    }
    
    .up_section .up_content .up_image_wrapper {
        
        left: 50%;
        transform: translateX(-50%);
    }
    .up_section .up_content {
        padding: 270px 30px 40px;
    }
    .up_section .user-btn {
        padding: 10px 33px;
        font-size: 12px;
    }
    .up_section .buttons {
        padding-top: 20px;
        flex-wrap: wrap;
        gap: none;

    }
  }
  @media (max-width: 575.98px){
    .user_profile {
        padding: 0 15px;
    }
    
   
    // .up_section .up_content {
    //     border: 0;
    // }
  }
