.form-design{
    background: #1A46B8;
    height: 100vh; 
}

.form-wrapper{
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.form-wrapper> div{
    margin-bottom: 20px;
}
.email.form-control{
    border:1px solid white; 
    font-size: 20px;
    width:353px;
    height:45px;
    top:376px;
    left:784px;
    padding-left: 44px;
}

.password.form-control{
    border:1px solid white; 
    font-size: 20px;
    width:353px;
    height:45px;
    top:376px;
    left:784px;
    padding-left: 44px;

}

.email.form-control:focus{
    color:white;
    background-color: #1A46B8;
    border: 1px solid white;
}

.password.form-control:focus{
    color:white;
    background-color: #1A46B8;
    border: 1px solid white;
}

.form-wrapper img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

.login_btn{
    width:353px;
    height:45px;
    top:376px;
    left:784px;
    background-color: white;
    color: #1A46B8;
    margin-bottom: 10px;
    border-radius: unset;
}

.forgot-password{
    color: white;
    margin-left: 35%;
}

.forgot-password:hover{
    color: lightblue;
}

.createBtn{
    width:353px;
    height:45px;
    top:376px;
    left:784px;
    background-color: #1A46B8;
}

.create_btn:hover{
    background-color: #95CDFF    ;
}
.authError{
    text-align: center;
    color: red;
}

.input_wrapper {
    position: relative;
}
.input_wrapper .input_field_icon {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px ;
    height: 20px;
}
.input_wrapper .input_field_icon img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    transform: translateY(10%);
}
.warn{
    position: absolute;
    bottom: -20px ;
    left: 0;
    width: 100%;
}